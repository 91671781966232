import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=16ca8b3d&scoped=true&"
import script from "./Template.vue?vue&type=script&lang=js&"
export * from "./Template.vue?vue&type=script&lang=js&"
import style0 from "./Template.vue?vue&type=style&index=0&id=16ca8b3d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.2_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21__yi3vgt22j4uhvmfodjmy3lwyxy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ca8b3d",
  null
  
)

export default component.exports