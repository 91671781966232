<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>基础配置</a-breadcrumb-item>
              <a-breadcrumb-item>模板管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="!visible" class="Enter-top-right">
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增模板
          </a-button>
        </div>
        <div class="flex-d-row" style="align-items: center" v-else>
          <a-button
            v-show="visb == true"
            style="margin-right: 15px"
            type="primary"
            @click.prevent="onsave"
          >
            保存
          </a-button>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '模板类型'"
                  placeholder="请选择模板类型"
                  style="width: 178px"
                  v-model="params['type'].value"
                >
                  <a-select-option value="0"> 意向合同模板 </a-select-option>
                  <a-select-option value="1"> 正式合同模板 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
          <!-- <a-row>
            
          </a-row> -->
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="templatelist"
              :rowClassName="rowClassName"
            >
              <span slot="status" slot-scope="text, record">
                <a-switch
                  :checked="text == 0 ? true : false"
                  default-checked
                  @change="onchangestate(record)"
                />
              </span>
              <span slot="type" slot-scope="text">
                {{
                  text == 0 ? '意向合同模板' : text == 1 ? '正式合同模板' : ''
                }}
              </span>
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
                <a @click="onEdittemp(record)">编辑模板</a>&nbsp;&nbsp;
                <a-popconfirm
                  title="确定要删除该模板?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="onDetele(record.id)"
                  @cancel="cancel"
                >
                  <a href="#">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
          'min-width': '1300px',
        }"
      >
        <a-form-model
          v-if="visb == false"
          ref="ruleForm"
          :rules="rules"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          style="margin-top: 15px"
        >
          <a-form-model-item label="模板类型">
            <a-select v-model="form.type">
              <a-select-option value="0"> 意向合同模板 </a-select-option>
              <a-select-option value="1"> 正式合同模板 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="模板名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="文件名">
            <a-radio-group @change="onchange" v-model="values">
              <a-radio :value="1"> 创建空白模板 </a-radio>
              <a-radio :value="2"> 上传已有模板 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-show="values == 2" label="上传模板">
            <div class="content-body">
              <a-upload
                :file-list="temp"
                :multiple="true"
                @preview="handlePreview"
                action=""
                :customRequest="handlefile"
                @change="handlefiles"
              >
                <a-button> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
            </div>
          </a-form-model-item>
          <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
          </a-form-item>
        </a-form-model>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '模板名称',
    dataIndex: 'name',
  },
  {
    title: '模板类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
const base64 = require('js-base64').Base64
export default {
  inject: ['reload'],
  data() {
    return {
      columns,
      Jssdk: '',
      iii: '',
      title: '',
      visb: false,
      values: 1,
      temp: [],
      visible: false,
      textlist: ['模板名称', '模板类型', '状态'],
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      params: {
        current: 1,
        pageSize: 10,
        name: {
          value: '',
          op: '%like%',
        },
        state: {
          value: undefined,
          op: '=',
        },
        type: {
          value: undefined,
          op: '=',
        },
      },
      total: 0,
      templatelist: [],
      value: 'name',
      key: '',
      selectedRowKey: [],
      form: {
        id: '',
        name: '',
        type: '0',
        fileName: '',
        fileId: '',
      },
    }
  },
  filters: {},
  methods: {
    async generatewps() {
      try {
        const res = await http.generatewps()
        const { success, data } = res.data
        if (success) {
          //   console.log(data.url);
          window.location.href = data.url
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    handleSearch(e) {
      //   console.log(this.params);
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['name'].value = values.模板名称
      })
      this.gettemplate()
    },
    handleReset() {
      this.params['type'].value = undefined
      this.params['state'].value = undefined
      this.fom.resetFields()
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    async handlePreview(file) {
      //   console.log(file.url);
      let urls = file.url.replaceAll(
        'sccncloud.oss-cn-shanghai.aliyuncs.com',
        'yunfiles.shangchan.cn'
      )
      if (file.url) {
        var url = urls //要预览文件的访问地址
        const u = 'https://preview.shangchan.cn/preview'
        window.open(
          u + '/onlinePreview?url=' + encodeURIComponent(base64.encode(url))
        )
      }
    },
    handlefiles({ fileList }) {
      if (this.temp.length > fileList.length) {
        this.temp = fileList
      }
    },
    handlefile(info) {
      //   if (this.temp.length > 0) {
      //     return;
      //   }

      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      if (
        info.file.name.split('.')[info.file.name.split('.').length - 1] !=
        'docx'
      ) {
        this.$message.error('暂不支持除docx以外其他类型文件!')
        return
      }
      this.putObject(
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    async putObject(client, id, name, data, realName) {
      try {
        const result = await client.put(`/template/${id}_0.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.temp.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: realName,
            uid: id,
            status: 'done',
            url: url,
          })
        })
        this.form.fileId = id
        this.form.fileName = name
        // console.log(this.temp);
      } catch (e) {
        console.log(e)
      }
    },
    onchange(e) {
      //   console.log(e.target.value);
      if (e.target.value == 1) {
        this.temp = []
        this.blanktemp()
      }
    },
    blanktemp() {
      let id = nanoid()
      let name = `template/${id}_0.docx`
      this.form.fileId = id
      this.form.fileName = 'docx'
      // 指定拷贝后的文件名称。
      client.copy(name, 'template/template.docx').then(r => {
        console.log(r.res.status)
      })
    },
    // 删除
    onDetele(e) {
      this.deletetemplate(e)
    },
    async deletetemplate(id) {
      try {
        const res = await http.deletetemplate({ id: id })
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.visible = true
      this.title = '编辑'
      let open = true
      this.gettemplateid(item.id, open)
    },
    onEdittemp(e) {
      //   setTimeout(() => {
      //     this.visible = true;
      //   }, 300);
      this.gettemplateid(e.id)
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.statetemplate(e.id, this.form.state)
    },
    async puttemplate(form) {
      try {
        const res = await http.puttemplate(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      if (this.form.id == '') {
        this.posttemplate(this.form)
      } else {
        this.puttemplate(this.form)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.gettemplate()
      // console.log(this.params.current);
    },
    showDrawer() {
      this.visible = true
      this.blanktemp()
    },
    async gettemplate() {
      try {
        const res = await http.gettemplate(this.params)
        const { success, data } = res.data
        if (success) {
          this.templatelist = data.templates.records
          this.total = data.templates.total
          //   console.log(data.modules);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async gettemplateid(id, open) {
      try {
        const res = await http.gettemplateid(id)
        const { success, data } = res.data
        if (success) {
          this.form.type = data.template.type + ''
          this.form.name = data.template.name
          this.form.fileName = data.template.fileName
          this.form.fileId = data.template.fileId
          //   if (data.template.fileName == "docx") {
          //     this.values = 1;
          //   } else {
          this.values = 2
          let url = client.signatureUrl(
            `template/${data.template.fileId}_0.${data.template.fileName}`
          )
          this.temp.push({
            bucket: 'sccncloud2',
            name: `template/${data.template.fileId}_0.${data.template.fileName}`,
            realName: `template/${data.template.fileId}_0.${data.template.fileName}`,
            uid: data.template.fileName,
            status: 'done',
            url: url,
          })
          //   }
          if (open != true) {
            this.visb = true
            localStorage.setItem('type', data.template.type)
            localStorage.setItem('wpsurl', data.template.url)
            setTimeout(() => {
              this.$router.push({
                name: 'edittemplate',
              })
            }, 300)
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onClose() {
      if (this.visb == true) {
        this.visb = false
        this.Jssdk.destroy()
        // this.reload();
      }
      this.values = 1
      this.visible = false
      this.temp = []
      this.form = {
        id: '',
        name: '',
        type: '0',
        fileName: '',
        fileId: '',
      }
    },
    // 新增
    async posttemplate(form) {
      try {
        const res = await http.posttemplate(form)
        const { success, data, msg } = res.data
        if (success) {
          //   console.log(data);
          this.$message.success('提交成功')
          this.gettemplateid(data.id)
          //   this.onClose();
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async statetemplate(id, state) {
      try {
        const res = await http.statetemplate(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.gettemplate()
      }, 500)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.gettemplate()
    this.getsts()
  },
  beforeRouteLeave(to, from, next) {
    if (this.Jssdk != '') {
      this.Jssdk.destroy()
    }
    this.$destroy()
    next()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ul .li {
  list-style: none;
  cursor: pointer;
  margin: 0 0 10px 0;
  padding: 5px;
  border: 1px solid #d9d9d9;
  width: 140px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
</style>
